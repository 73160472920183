import { forkJoin, from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { OfflineEventType } from './constants';
import { Manifest } from './manifest';
import { ManifestService } from './manifest.service';
import { FetchOptions, NetworkService } from './network-service';
import { OfflineEvent } from './offline.event';

export class OfflineStorage {
  network = new NetworkService();
  manifestService: ManifestService;
  manifest: Manifest;

  constructor() {}

  message(event: OfflineEvent): Observable<string> {
    if (!event) return of(null);

    this.manifestService = new ManifestService(event.ArgoUrl, event.ProductDataUrl);

    switch (event.EVENT_TYPE) {
      case OfflineEventType.Download:
        return this.download(event);
      case OfflineEventType.Delete:
        return from(this.deleteCommunity(event));
      case OfflineEventType.ClearAll:
        return from(this.clearCache());
      default:
        break;
    }
  }

  download(event: OfflineEvent): Observable<string> {
    const communityData$ = this.updateCommunityDataCache(event);
    const productData$ = this.manifestService.updateProductData(event);

    return communityData$.pipe(
      mergeMap(() => productData$),
      mergeMap((offlineEvent: OfflineEvent) => of(JSON.stringify(offlineEvent)))
    );
  }

  updateCommunityDataCache(event: OfflineEvent): Observable<boolean> {
    const requestUrl = `${event.ArgoUrl}/api/communities/${event.CommunityId}?dataLevel=2&filterToActiveOnly=true`;

    const communityData$ = from(this.updateCommunityData(event, requestUrl));
    const cachedCommunityData$ = from(caches.open('communityData'));
    const requestUrl$ = from([requestUrl]);

    return forkJoin([communityData$, cachedCommunityData$, requestUrl$]).pipe(
      mergeMap(([response, cache, url]: [Response, Cache, string]) => {
        cache.put(url, response);
        return of(true);
      })
    );
  }

  updateCommunityData(event: OfflineEvent, requestUrl: string): Observable<Response> {
    const options = new FetchOptions('GET', event.HttpHeaders);
    return this.network.fetch(requestUrl, options);
  }

  async deleteCommunity(event: OfflineEvent): Promise<string> {
    const communityDataCache = await caches.open('communityData');
    const communityDataCacheRequests = await communityDataCache.keys();

    if (!communityDataCacheRequests || communityDataCacheRequests.length < 2)
      return await this.clearCache();

    await this.deleteCommunityData(event.CommunityId);
    await this.manifestService.deleteProductData(event);
    await this.manifestService.deleteManifest(event.CommunityId);

    return JSON.stringify({ EVENT: OfflineEventType.Delete });
  }

  async deleteCommunityData(communityId: number): Promise<boolean> {
    const communityDataCache = await caches.open('communityData');
    const requests: readonly Request[] = await communityDataCache.keys();
    const request = requests.find(req => req.url.indexOf(`api/communities/${communityId}?`) > -1);
    return await communityDataCache.delete(request);
  }

  async clearCache(): Promise<string> {
    let result = '';
    let cleared = true;

    for (const key of await caches.keys()) {
      cleared = cleared && (await caches.delete(key));
    }

    result = cleared ? 'true' : 'false';

    return JSON.stringify({ EVENT: OfflineEventType.ClearAll });
  }
}
