export enum OfflineEventType {
  Download,
  ClearAndReload,
  ClearAll,
  Delete,
  PercentCheck,
  UpdateSW,
  UpdateAvailable,
  UpdateSuccessful,
  PercentVerified,
  FailedVerification
}
