import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppModeTypes } from '../utility/helpers/app-mode.enum';
import { Lookup } from '../utility/helpers/lookup';
import { NavigationService, PageTitle } from '../utility/services/navigation.service';
import { OfflineService } from '../utility/services/offline.service';
import { SessionStorageService } from '../utility/services/session-storage.service';
import { ErrorReasons } from './error-reasons.enum';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorComponent implements OnInit {
  errorReason: ErrorReasons;
  errorReasons = ErrorReasons;
  attemptedRoute: string;
  attemptedOfflineCommunityId = false;
  currentProduct: AppModeTypes;

  constructor(
    private route: ActivatedRoute,
    private navigator: NavigationService,
    private offlineService: OfflineService,
    private lookup: Lookup,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit() {
    if (!this.attemptedOfflineCommunityId) {
      this.attemptedOfflineCommunityId = true;
      const communityId = this.offlineService.getFirstDownloadedCommunityId();
      const attemptedCommunityId = this.sessionStorageService.getItem(
        this.lookup.CurrentCommunityIdSessionKey
      );
      if (communityId && attemptedCommunityId && communityId === +attemptedCommunityId) {
        this.navigator.go({
          PageTitle: PageTitle.Homepage,
          RouteParams: { CommunityId: communityId }
        });
      }
    }

    this.errorReason = +this.route.snapshot.queryParams.reason;
    this.attemptedRoute = this.route.snapshot.queryParams.route;
    this.currentProduct = this.navigator.AppMode;
  }
}
