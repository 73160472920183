import { Notification, from, fromEvent, Observable, Subject, zip, of } from 'rxjs';
import { map, filter, dematerialize, concatMap, materialize, tap, mergeAll, finalize } from 'rxjs/operators';

/** @internal */
function workerIsTransferableType(worker) {
  return !!worker.selectTransferables;
}
/** @internal */
function workerIsUnitType(worker) {
  return !!worker.workUnit;
}
/** @internal */
function getWorkerResult(worker, incomingMessages$) {
  const input$ = incomingMessages$.pipe(map(e => e.data), map(n => new Notification(n.kind, n.value, n.error)),
  // ignore complete, the calling thread will manage termination of the stream
  filter(n => n.kind !== 'C'), dematerialize());
  return workerIsUnitType(worker) ? input$.pipe(concatMap(input => from(worker.workUnit(input)).pipe(materialize()))) : worker.work(input$).pipe(materialize());
}
function runWorker(workerConstructor) {
  const worker = new workerConstructor();
  const incomingMessages$ = fromEvent(self, 'message');
  return getWorkerResult(worker, incomingMessages$).subscribe(notification => {
    // type to workaround typescript trying to compile as non-webworker context
    const workerPostMessage = postMessage;
    if (workerIsTransferableType(worker) && notification.hasValue) {
      workerPostMessage(notification, worker.selectTransferables(notification.value));
    } else {
      workerPostMessage(notification);
    }
  });
}

/**
 * @deprecated - use the `runWorker(YourWorkerClass)` strategy instead, for
 * compatibility with future webpack versions, and a slightly smaller bundle
 * @see https://github.com/cloudnc/observable-webworker#decorator-deprecation-notice
 */
function ObservableWorker() {
  return workerConstructor => {
    runWorker(workerConstructor);
  };
}
function fromWorker(workerFactory, input$, selectTransferables, options = {
  terminateOnComplete: true
}) {
  return new Observable(responseObserver => {
    let worker;
    let subscription;
    try {
      worker = workerFactory();
      worker.onmessage = ev => responseObserver.next(ev.data);
      worker.onerror = ev => responseObserver.error(ev);
      subscription = input$.pipe(materialize(), tap(input => {
        if (selectTransferables && input.hasValue) {
          const transferables = selectTransferables(input.value);
          worker.postMessage(input, transferables);
        } else {
          worker.postMessage(input);
        }
      })).subscribe();
    } catch (error) {
      responseObserver.error(error);
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      if (worker && options.terminateOnComplete) {
        worker.terminate();
      }
    };
  }).pipe(map(({
    kind,
    value,
    error
  }) => new Notification(kind, value, error)), dematerialize());
}
function fromWorkerPool(workerConstructor, workUnitIterator, options) {
  const {
    // tslint:disable-next-line:no-unnecessary-initializer
    selectTransferables = undefined,
    workerCount = navigator.hardwareConcurrency ? navigator.hardwareConcurrency - 1 : null,
    fallbackWorkerCount = 3,
    flattenOperator = mergeAll()
  } = options || {};
  return new Observable(resultObserver => {
    const idleWorker$$ = new Subject();
    let completed = 0;
    let sent = 0;
    let finished = false;
    const lazyWorkers = Array.from({
      length: workerCount !== null ? workerCount : fallbackWorkerCount
    }).map((_, index) => {
      let cachedWorker = null;
      return {
        factory() {
          if (!cachedWorker) {
            cachedWorker = workerConstructor(index);
          }
          return cachedWorker;
        },
        terminate() {
          if (!this.processing && cachedWorker) {
            cachedWorker.terminate();
          }
        },
        processing: false,
        index
      };
    });
    const processor$ = zip(idleWorker$$, workUnitIterator).pipe(tap(([worker]) => {
      sent++;
      worker.processing = true;
    }), finalize(() => {
      idleWorker$$.complete();
      finished = true;
      lazyWorkers.forEach(worker => worker.terminate());
    }), map(([worker, unitWork]) => {
      return fromWorker(() => worker.factory(), of(unitWork), selectTransferables, {
        terminateOnComplete: false
      }).pipe(finalize(() => {
        completed++;
        worker.processing = false;
        if (!finished) {
          idleWorker$$.next(worker);
        } else {
          worker.terminate();
        }
        if (finished && completed === sent) {
          resultObserver.complete();
        }
      }));
    }), flattenOperator);
    const sub = processor$.subscribe(resultObserver);
    lazyWorkers.forEach(w => idleWorker$$.next(w));
    return () => sub.unsubscribe();
  });
}

/*
 * Public API Surface of observable-webworker
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ObservableWorker, fromWorker, fromWorkerPool, getWorkerResult, runWorker, workerIsTransferableType, workerIsUnitType };
