import { OfflineEventType } from './constants';

export class OfflineEvent {
  ArgoUrl: string;
  ProductDataUrl: string;
  Host: string;
  DNSHost: string;
  CommunityDirectory: string;
  CommunityId: number;
  CommunityDownloaded: boolean;
  EVENT_TYPE: OfflineEventType;
  HttpHeaders: any;
  Progress: number;
  DownloadComplete = false;
  MissingFiles = new Array<string>();
  UpdateAvailable = false;

  constructor(
    event?: OfflineEventType,
    communityId?: number,
    argoUrl?: string,
    productDataUrl?: string
  ) {
    this.EVENT_TYPE = event;
    this.CommunityId = communityId;
    this.ArgoUrl = argoUrl;
    this.ProductDataUrl = productDataUrl;
  }
}
