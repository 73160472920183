import { Observable } from 'rxjs';

export class FetchOptions {
  method: string;
  headers: any;
  data: any;

  constructor(method: string, headers?: any, data?: any) {
    this.method = method || 'GET';
    this.headers = headers || {};
    if (data) {
      switch (typeof data) {
        case 'string':
          this.data = data;
          break;
        case 'object':
          this.data = JSON.stringify(this.data);
          break;
        default:
          this.data = data.toString();
      }
    }
  }
}

export class NetworkService {
  private isOnline: boolean;

  online(): boolean {
    return this.isOnline;
  }

  fetch(url: string, body?: FetchOptions): Observable<Response> {
    const request = new XMLHttpRequest();

    return new Observable(observer => {
      try {
        request.onreadystatechange = () => {
          if (request.readyState !== 4) return;

          if ((request.status >= 200 && request.status < 300) || request.status >= 400) {
            observer.next(
              new Response(request.response, {
                status: request.status,
                statusText: request.statusText,
                headers: this.getHeaders(url)
              })
            );
            observer.complete();
          } else {
            observer.error(request.response);
          }
        };

        const method = body && body.method ? body.method : 'GET';
        request.open(method, url, true);

        if (body && body.headers) {
          Object.keys(body.headers).forEach((key, val) =>
            request.setRequestHeader(key, body.headers[key])
          );
        }

        if (this.requestingImage(url)) {
          request.responseType = 'arraybuffer';
        }

        if (body && body.data) {
          request.send(body.data);
        } else {
          request.send();
        }
      } catch (ex) {
        observer.error(ex);
      }
    });
  }

  requestingImage(url: string): boolean {
    const match = url.match(/\.svg|\.jpg|\.png|\.gif\.jpeg/);
    return match && match.length > 0;
  }

  getHeaders(url: string) {
    const headers = new Headers();
    headers.append('Content-Type', this.getFileType(url));
    headers.append('url', url);
    return headers;
  }

  getFileType(url: string): string {
    const fileTypeArray = {
      '.css': 'text/css',
      '.htm': 'text/html',
      '.html': 'text/html',
      '.svg': 'image/svg+xml',
      '.jpg': 'image/jpeg',
      '.gif': 'image/jpeg',
      '.png': 'image/png',
      '.tff': 'font/tff',
      '.eot': 'font/eot',
      '.otf': 'font/otf',
      '.woff': 'font/woff',
      '.woff2': 'font/woff2',
      '.js': 'application/json',
      '.js.map': 'application/json'
    };

    const index = url.lastIndexOf('.');

    if (index === -1) {
      return 'text/html';
    }

    return fileTypeArray[url.substring(index)];
  }
}
