import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Lookup } from '../helpers/lookup';

import { getArgoHeaders } from '@ml/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private lookup: Lookup) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // if skipping auth also clear out the SkipAuthHeader itself
    if (req.headers.has(this.lookup.SkipAuthHeader))
      return next.handle(req.clone({ headers: new HttpHeaders() }));

    req = this.ensureFullDomain(req);
    req = this.addAuthHeaders(req);
    return next.handle(req);
  }

  public addAuthHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const authHeaders = getArgoHeaders({ url: req.url, method: req.method });

    return req.clone({ headers: new HttpHeaders(authHeaders) });
  }

  private ensureFullDomain(req: HttpRequest<any>): HttpRequest<any> {
    if (req.url.startsWith('/api'))
      return req.clone({ url: `${this.lookup.ApiBaseUrl}${req.url}` });
    else return req;
  }
}
