import { from, Observable, of, Subscriber } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';

import { OfflineEventType } from './constants';
import { Manifest, ManifestItem } from './manifest';
import { FetchOptions, NetworkService } from './network-service';
import { OfflineEvent } from './offline.event';

export class ManifestService {
  networkService = new NetworkService();
  ArgoUrl: string;
  ProductDataUrl: string;

  constructor(argoUrl: string, productDataUrl: string) {
    this.ArgoUrl = argoUrl;
    this.ProductDataUrl = productDataUrl;
  }

  updateProductData(event: OfflineEvent): Observable<OfflineEvent> {
    const getCachedManifest$: Observable<Manifest> = from(
      this.getManifest(event.CommunityId, event)
    );

    const parseDiffManifest$ = (response: Response): Observable<Manifest> =>
      from(this.parseManifestResponse(response, event));

    const downloadMissingFiles$ = (files: Array<string>) => this.downloadFiles(files);

    let diff: Manifest;
    let updated: Manifest;
    return getCachedManifest$.pipe(
      mergeMap(this.getManifestDiff),
      mergeMap(parseDiffManifest$), // diffManifest, new server manifest
      withLatestFrom(getCachedManifest$), // cachedManfiest, allows cached manifest to be passed in as a parameter
      mergeMap(([diffManifest, cachedManifest]) => {
        const updatedManifest = this.mergeManifests(cachedManifest, diffManifest);
        diff = diffManifest;
        updated = updatedManifest;
        return this.saveManifest(updatedManifest);
      }),
      mergeMap(() => this.deleteFromCache(diff, updated)),
      mergeMap((diffManifest: Manifest) => this.addOrUpdateProductData(diffManifest)),
      mergeMap((communityEvent: OfflineEvent) => this.verifyDownload(updated, communityEvent)),
      mergeMap((communityEvent: OfflineEvent) => {
        if (communityEvent.DownloadComplete) {
          return downloadMissingFiles$(communityEvent.MissingFiles);
        } else {
          return of(communityEvent);
        }
      })
    );
  }

  private getManifestDiff = (manifest: Manifest): Observable<Response> => {
    const options = new FetchOptions('POST', null, manifest.ToString());
    options.headers['Content-Type'] = 'application/json';

    const path = `${this.ArgoUrl}/atlas/manifest/diffJSON?ngsw-bypass=true`;
    return this.networkService.fetch(path, options);
  };

  async getManifest(id: number, event: OfflineEvent): Promise<Manifest> {
    const manifestCache = await caches.open('manifest');
    const manifestResponse = await manifestCache.match(this.getCachedManifestLocation(id));
    return this.parseManifestResponse(manifestResponse, event);
  }

  async saveManifest(manifest: Manifest): Promise<void> {
    const manifestCache = await caches.open('manifest');
    return await manifestCache.put(
      this.getCachedManifestLocation(manifest.Id),
      new Response(manifest.ToString())
    );
  }

  async deleteManifest(id: number): Promise<boolean> {
    const manifestCache = await caches.open('manifest');
    return await manifestCache.delete(this.getCachedManifestLocation(id));
  }

  async parseManifestResponse(response: Response, event: OfflineEvent): Promise<Manifest> {
    if (!response || response.status >= 400) return new Manifest(event);

    const rawManifest = await response
      .json()
      .catch(err => console.error('Error JSON response: ' + err));

    const manifest = new Manifest(event);

    if (!manifest.BaseDirectory) {
      manifest.BaseDirectory = rawManifest.BaseDirectory || '';
    }

    if (!manifest.ManifestKind) {
      manifest.ManifestKind = rawManifest.ManifestKind;
    }

    if (!manifest.Id && rawManifest.Id) manifest.Id = rawManifest.Id;

    if (rawManifest?.Items) {
      for (const itemKey in rawManifest.Items) {
        if (rawManifest.Items.hasOwnProperty(itemKey)) {
          const item = new ManifestItem();

          item.Command = rawManifest.Items[itemKey].Command;
          item.FilePath = rawManifest.Items[itemKey].FilePath;
          item.Hash = rawManifest.Items[itemKey].Hash;
          item.RequestUseOfFullPath = rawManifest.Items[itemKey].RequestUseOfFullPath;
          item.Size = rawManifest.Items[itemKey].Size;

          manifest.Items.set(itemKey, item);
        }
      }
    }

    return manifest;
  }

  async deleteFromCache(diffManifest: Manifest, updatedManifest: Manifest): Promise<Manifest> {
    const cacheKeys = await caches.keys();

    // the request could be found in multiple caches
    // delete in all positions prior to updating
    for (const cacheKey of cacheKeys) {
      const cache = await caches.open(cacheKey);

      for (const command of diffManifest.DeleteCommands) {
        const uri = this.itemPathToResourcePath(diffManifest.BaseDirectory, command.CommandValue);
        await cache.delete(uri);
      }

      for (const item of diffManifest.Items.values()) {
        const uri = this.itemPathToResourcePath(diffManifest.BaseDirectory, item.FilePath);
        await cache.delete(uri);
      }
    }

    const productdata = await caches.open('productdata');
    const requests = await productdata.keys();

    // Check all product data cached.
    // If its not in the manifest, delete it
    const decodedLowerCaseUris = requests.map(request => decodeURI(request.url).toLowerCase());
    const files = this.getFileNames(updatedManifest);

    for (let i = 0; i < decodedLowerCaseUris.length; ++i) {
      const path = decodedLowerCaseUris[i]
        .replace(this.ProductDataUrl, '')
        .replace(updatedManifest.BaseDirectory, '');
      const source = files.find(file => file.includes(path));
      if (!source) {
        await productdata.delete(requests[i]);
      }
    }

    return diffManifest;
  }

  addOrUpdateProductData(diffManifest: Manifest): Observable<OfflineEvent> {
    if (diffManifest.Items.size === 0) {
      return of(this.updateEvent(100, true));
    }

    return this.downloadFiles(this.getFileNames(diffManifest));
  }

  downloadFiles(files: Array<string>): Observable<OfflineEvent> {
    if (files.length === 0) {
      const event = new OfflineEvent();
      event.DownloadComplete = true;
      event.Progress = 100;
      return of(event);
    }

    let cache: Cache;
    let count = 0;

    const pathSource$ = from(files);
    const productDataCache$ = from(caches.open('productdata'));

    return new Observable((observer: Subscriber<OfflineEvent>) => {
      productDataCache$
        .pipe(
          mergeMap((productDataCache: Cache) => {
            cache = productDataCache;
            return pathSource$;
          }),
          mergeMap((path: string): Observable<Response> => this.networkService.fetch(path)),
          catchError(err => {
            console.log('issue downloading a resource but still count % anyway');
            console.log(err);
            ++count;
            return err;
          }),
          mergeMap((response: Response) => cache.put(response.headers.get('url'), response))
        )
        .subscribe(() => {
          ++count;
          if (count % 10 === 0 || count === files.length) {
            observer.next(
              this.updateEvent(
                Math.ceil((count / files.length) * 100),
                count === files.length,
                true
              )
            );
          }

          if (count === files.length) {
            observer.complete();
          }
        });
    });
  }

  async verifyDownload(manifest: Manifest, event: OfflineEvent): Promise<OfflineEvent> {
    const missing = new Array<string>();

    if (!event) {
      event = new OfflineEvent();
    }

    if (event.DownloadComplete) {
      const cache = await caches.open('productdata');
      const keys = await cache.keys();
      const decodedLowerCaseUris = keys.map(request => decodeURI(request.url).toLowerCase());
      const files = this.getFileNames(manifest);

      for (const file of files) {
        const uri = this.itemPathToResourcePath(manifest.BaseDirectory, file).toLowerCase();
        if (!decodedLowerCaseUris.some(lowerUri => lowerUri === uri)) {
          missing.push(file);
        }
      }

      event.MissingFiles = missing;
      if (event.MissingFiles.length === 0) {
        event.Progress = 100;
        event.DownloadComplete = true;
      }
    }

    return event;
  }

  async deleteProductData(event: OfflineEvent): Promise<boolean> {
    const manifest = await this.getManifest(event.CommunityId, event);

    const productDataCache = await caches.open('productdata');

    let cleared = true;
    for (const key of manifest.Items.keys()) {
      cleared =
        cleared &&
        (await productDataCache.delete(this.itemPathToResourcePath(manifest.BaseDirectory, key)));
    }

    return cleared;
  }

  private updateEvent(
    percentComplete: number,
    downloadComplete: boolean = false,
    updateAvailable: boolean = false
  ): OfflineEvent {
    const event = new OfflineEvent();
    event.EVENT_TYPE = OfflineEventType.Download;
    event.Progress = percentComplete;
    event.DownloadComplete = downloadComplete;
    event.UpdateAvailable = updateAvailable;
    return event;
  }

  mergeManifests(cachedManifest: Manifest, diffManifest: Manifest) {
    if (!diffManifest) return cachedManifest;

    diffManifest.Items.forEach(item => {
      item.Command = '';
      cachedManifest.Items.set(item.FilePath, item);
    });

    diffManifest.DeleteCommands.forEach(deletedItem => {
      cachedManifest.Items.delete(deletedItem.CommandValue);
    });

    return cachedManifest;
  }

  itemPathToResourcePath(baseDirectory: string, path: string): string {
    if (path.indexOf('http') > -1) return path;

    if (this.clientLevel(path)) {
      // Remove the community path as resource is on the client level
      // Likely for client theme
      baseDirectory = '';
    }

    if (path.indexOf('productdata') > -1) {
      // Product data will go through argo api instead of the old /productdata or /resources/productdata
      // that was used in Kraken and ICON
      path = path.replace('/productdata', '');
    }

    const result = `/${baseDirectory}/${path}`.replace(/\/{2,}/g, '/');
    return this.ProductDataUrl + result;
  }

  clientLevel(path: string): boolean {
    return path.indexOf('settings-files') > -1 || path.indexOf('_CustomSubPages') > -1;
  }

  getCachedManifestLocation(id: number): string {
    return `${this.ArgoUrl}/atlas/manifest/diffJSON/${id}`;
  }

  getFileNames(manifest: Manifest): Array<string> {
    const result = new Array<string>();

    manifest.Items.forEach((item: ManifestItem, path: string) => {
      result.push(this.itemPathToResourcePath(manifest.BaseDirectory, path));
    });

    return this.filterExcessFiles(result);
  }

  filterExcessFiles(fileNames: Array<string>): Array<string> {
    return fileNames.filter(file => {
      file = file?.toLowerCase();
      return (
        file &&
        !file.includes('.ashx') &&
        !file.includes('.rar') &&
        !file.includes('.zip') &&
        !file.includes('.txt') &&
        !file.includes('communitydata.json') &&
        !file.includes('community.manifest') &&
        !file.includes('/overrides/') &&
        !file.includes('.min.js.map') &&
        !file.includes('/temp/') &&
        !file.includes('.vml') &&
        !file.includes('/client_resources/') &&
        !file.includes('/page_content_images/') &&
        !file.includes('/googlemapsmarker/') &&
        !file.includes('/panos/') &&
        !file.includes('/_imagesets/') &&
        !file.includes('visualizer') &&
        (!file.includes('/thumbs/') || file.includes('/thumbs/sm') || file.includes('/thumbs/lg'))
      );
    });
  }
}
