import { OfflineEvent } from './offline.event';

export class Manifest {
  BaseDirectory = '';
  ManifestKind = '';
  Id = 0;
  Items = new Map<string, ManifestItem>();
  GlobalCommands = new Array<ManifestCommand>();
  DeleteCommands = new Array<ManifestCommand>();

  constructor(communityEvent?: OfflineEvent) {
    this.ManifestKind = 'Community';

    if (communityEvent) {
      this.Id = communityEvent.CommunityId;
      this.BaseDirectory = communityEvent.CommunityDirectory;
    }
  }

  // JSON.stringify does NOT stringify Maps
  ToString(): string {
    const result: any = {
      Items: {}
    };

    if (this.Items) {
      this.Items.forEach((value, key) => {
        result.Items[key] = {
          Command: value.Command,
          FilePath: value.FilePath,
          Hash: value.Hash,
          RequestUseOfFullPath: value.RequestUseOfFullPath,
          Size: value.Size
        };
      });
    }

    result.BaseDirectory = this.BaseDirectory;
    result.ManifestKind = this.ManifestKind;
    result.Id = this.Id;

    return JSON.stringify(result);
  }
}

export class ManifestCommand {
  CommandName: string;
  CommandValue: string;
}

export class ManifestItem {
  FilePath: string;
  Hash: string;
  Command: string;
  RequestUseOfFullPath: boolean;
  Size: number;
}
