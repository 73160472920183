import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fromWorker } from 'observable-webworker';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Lookup } from '../helpers/lookup';
import { DeviceVM } from '../models/device';
import { SavedCommunity } from '../models/saved-community';
import { OfflineEventType } from '../offline/constants';
import { OfflineStorage } from '../offline/offline-storage';
import { OfflineEvent } from '../offline/offline.event';
import { ApiService } from './api.service';
import { AuthInterceptor } from './auth.interceptor';
import { CommunityService } from './community.service';
import { LocalStorageService } from './local-storage.service';

import { ICommunity } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  readonly offlineDeviceId = 'OfflineDeviceId';
  readonly offlineDeviceNotes = 'OfflineDeviceNotes';

  constructor(
    private apiService: ApiService,
    private communitySerivce: CommunityService,
    private lookup: Lookup,
    private localStorageService: LocalStorageService
  ) {}

  download(community: ICommunity): Observable<OfflineEvent> {
    const event = new OfflineEvent(
      OfflineEventType.Download,
      community.CommunityId,
      this.lookup.ApiBaseUrl,
      this.lookup.ApiProductDataEndpoint
    );

    event.HttpHeaders = this.getHeaders(event);
    event.CommunityDirectory = community.VirtualDirectory;

    const input$: Observable<string> = of(JSON.stringify(event));
    return fromWorker<string, string>(
      () =>
        new Worker(new URL('../offline/.worker', import.meta.url), {
          name: 'offline-worker',
          type: 'module'
        }),
      input$
    ).pipe(
      catchError((error, ob) => {
        console.log('Error occurred while downloading', error);
        return ob;
      }),
      mergeMap(
        (response): Observable<OfflineEvent> =>
          of(Object.assign(new OfflineEvent(), JSON.parse(response)))
      )
    );
  }

  isDownloaded(communityId: number): boolean {
    return this.getDownloadedCommunities().some(com => com.CommunityId === communityId);
  }

  getFirstDownloadedCommunityId(): number {
    const communities = this.getDownloadedCommunities();
    return communities.length > 0 ? communities[0].CommunityId : 0;
  }

  getDownloadedCommunities(): Array<SavedCommunity> {
    let communities = new Array<SavedCommunity>();

    const localOffline = this.localStorageService.getItem('OfflineCommunity');
    if (localOffline) {
      try {
        const raw: Array<any> = JSON.parse(localOffline);
        if (raw) {
          communities = raw.map(com => Object.assign(new SavedCommunity(), com));
        }
      } catch (e) {
        this.localStorageService.removeItem('OfflineCommunity');
      }
    }

    return communities;
  }

  getDeviceInfo(): DeviceVM {
    const device = new DeviceVM();
    device.Id = this.deviceId();
    device.Notes = this.deviceNotes();
    return device;
  }

  isTokenValid(token: string): boolean {
    return this.communitySerivce.current.OfflineToken === token;
  }

  deviceNotes(): string {
    return this.localStorageService.getItem(this.offlineDeviceNotes) || '';
  }

  saveNotes(device: DeviceVM, communityId: number) {
    const oldNotes = this.localStorageService.getItem(this.offlineDeviceNotes);
    if (oldNotes !== device.Notes) {
      this.localStorageService.setItem(this.offlineDeviceNotes, device.Notes);
      const path = `/deviceinstall/${device.Id}/${communityId}/devicenotes`;
      this.apiService.update({ DeviceNotes: device.Notes }, 'analytic', path);
    }
  }

  deviceId(): string {
    let result = this.localStorageService.getItem(this.offlineDeviceId);

    if (!result) {
      result = this.generateGuid();
      this.localStorageService.setItem(this.offlineDeviceId, result);
    }

    return result;
  }

  deleteCommunity(communityId: number): Observable<OfflineEvent> {
    const event = new OfflineEvent(
      OfflineEventType.Delete,
      communityId,
      this.lookup.ApiBaseUrl,
      this.lookup.ApiProductDataEndpoint
    );

    // Deleting files is quick and should not require the use of a web worker
    const offlineStorage = new OfflineStorage();
    return offlineStorage
      .message(event)
      .pipe(mergeMap(response => of(Object.assign(new OfflineEvent(), JSON.parse(response)))));
  }

  getCommunityLink(communityId: number): string {
    return `${window.location.origin}/scp/${communityId}/home`;
  }

  private generateGuid(): string {
    // Where give credit where credit is due
    // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0;

      // eslint-disable-next-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  private getHeaders(event: OfflineEvent) {
    const result = {};
    const requestUrl = `${event.ArgoUrl}/api/communities/${event.CommunityId}?dataLevel=2&filterToActiveOnly=true`;
    let request = new HttpRequest('GET', requestUrl);

    const interceptor = new AuthInterceptor(this.lookup);
    request = interceptor.addAuthHeaders(request);
    request.headers.keys().forEach(key => (result[key] = request.headers.get(key)));

    return result;
  }
}
